<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="bankCategories"
          :search="search"
          :total-pages.sync="totalPages"
          title="Bank Kategori"
          subtitle="Halaman untuk menambahkan kategori bank soal"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Bank Kategori': 'Edit Bank Kategori'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="bankCategory.name"
                :rules="[validation.required]"
                label="Bank Kategori"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Bank Kategori"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'BankCategory',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      page: 1,
      isLoadingData: false,
      isLoadingTable: false,
      isLoadingButton: false,
      totalItems: 0,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'bankcategory',
      bankCategories: [],
      bankCategory: {
        name: '',
      },
      bankCategoryUuid: '',
      validation: {
        required,
        integerValidator,
      },
      filterQuery: {
        search: '',
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Kategori', value: 'name' },
        { text: 'Aksi', value: 'actions' },
      ],
      formValid: false,
    }
  },
  watch: {
    bankCategory: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.bankCategory).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.getBankCategory(this.filterQuery)
      },
    },
    search: {
      handler() {
        this.getBankCategory(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.getBankCategory()
    this.isLoadingTable = false
  },
  methods: {
    searchHandler(data) {
      this.filterQuery.search = data
      this.page = 1
      this.getBankCategory(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async getBankCategory(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(({ data }) => {
        this.bankCategories = data.data.map((bankCategories, index) => ({
          ...bankCategories,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
    },
    resetForm() {
      this.bankCategory.name = ''
    },
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },
    showFormEdit(uuid) {
      this.dialog = 'edit'
      this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.bankCategory = data.data
        this.bankCategoryUuid = data.data.uuid
      })
      this.modalDialog = true
    },

    async update() {
      this.isLoadingButton = true
      if (this.bankCategory.name === '') {
        this.showSnackbar({
          text: 'Silahkan isi data terlebih dahulu',
          color: 'success',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.update(this.service, this.bankCategory, this.bankCategoryUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.getBankCategory(this.filterQuery)
          this.resetForm()
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.getBankCategory(this.filterQuery)
      this.modalDialog = false
      this.isLoadingButton = false
      // this.resetForm()
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.bankCategory).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.resetForm()
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.getBankCategory()
      this.getBankCategory(this.filterQuery)
      this.isLoadingButton = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.bankCategoryUuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.bankCategoryUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.getBankCategory(this.filterQuery)
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.getBankCategory(this.filterQuery)
      // if (this.search) {
      //   await this.searchHandler()
      //   this.getBankCategory()
      // }
      this.isLoadingButton = false
      this.confirmDialog = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
